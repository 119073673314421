html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.swiper-slide {
  background-position: 0 0;
}

.swiper-slide {
  width: 100%;
}

.swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  left: 8%;
  transform: translateY(-50%);
  width: 10px;
}

.swiper-pagination {
  transform: translateZ(1px);
}

.swiper-pagination-bullet {
  background-color: rgba(85, 85, 85, 0.2);
  width: 8px;
  height: 8px;
  border: 1px solid #d3af5f;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #d3af5f;
  opacity: 1;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 11px;
  color: #d3af5f;
  transform: translateZ(1px);
  background-color: rgba(85, 85, 85, 0.3);
  border-radius: 50%;
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: #d3af5f;
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6px;
  transform: rotate(-90deg);
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  right: 91.5%;
}
